import * as React from "react"
import PreviewArticleList from "./article/preview-article-list"
import TitlePageComponent from "./title-page"
import Layout from "./layout/layout"
import { graphql } from 'gatsby'
import { useTranslation,  useI18next } from "gatsby-plugin-react-i18next";
import SEOComponent from "./seo"
import FilterRessources from "./shared/filter-ressources"
import { Pagination } from "@mui/material"


const RessourcesPage = ({ data, pageContext }) => {
  // UseEffect hook is utilized to handle the side effect of getting URL from window
  const [url, setUrl] = React.useState('');
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(window.location.href);
    }
  }, []);

  // This is used to extract 'video', 'article' or 'other' from the URL.
  const lastPart = (url.match(/\/(video|article|other)(\/|$)/) || [])[1] || "";

  const { t, i18n } = useTranslation();
  const { navigate } = useI18next();

  const siteContent = data.siteContent;
  const articles = data.ressourcesArticles.nodes;

  const onFilter = (tag) => navigate(`/ressources/${tag}`);
  
  const handleChange = (event, value) => {
    // Ternary operator instead of if statement
    value = value === 1 ? "" : value;
    navigate(`/ressources${lastPart ? `/${lastPart}` : ""}/${value}`);
  };
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("menu_ressources")} />
        <TitlePageComponent title={t("menu_ressources")} description={siteContent.ressources.description}></TitlePageComponent>
        <FilterRessources onFilter={onFilter} activeKey={lastPart}></FilterRessources>
        <PreviewArticleList articles={articles} ></PreviewArticleList>
        <div className="flex flex-col container items-center">
        {
          pageContext.numberOfPages > 1 &&
            <Pagination count={pageContext.numberOfPages } page={pageContext.pageNumber +1} onChange={handleChange} size="large" color="secondary" />
        }
          </div>
      </Layout>
    </main>
  )
}
export default RessourcesPage;

export const query = graphql`
query($language: String!, $skip: Int!, $limit: Int!, $filterTag : StringQueryOperatorInput ) {
  locales: allLocale(filter:{language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  siteContent(language: {eq: $language}) {
        editionYear
        programYear
        ressources {
          description
        }
        language
        cookieURL
        personalDataURL
        banner
  }
  ressourcesArticles: allArticle(
    skip: $skip 
    limit: $limit,
    sort: {fields: datePublished, order: DESC},
    filter: {
      typeArticle: {eq: ressources}, 
      language: {eq: $language},
      tags : $filterTag
  }) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        tags
        categories
      }
      
    }
  }
`