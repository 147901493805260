import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";

const FilterRessources = ({ onFilter, activeKey }) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-center gap-5 mb-10 flex-wrap">
            <span role="button" tabIndex={0} onClick={() => onFilter("")} onKeyDown={() => onFilter("")} className={`filter-tag ${activeKey === '' ? "active-filter" : ""}`}>{t("all")}</span>
            <span role="button" tabIndex={0} onClick={() => onFilter("article")} onKeyDown={() => onFilter("article")} className={`filter-tag ${activeKey === 'article' ? "active-filter" : ""}`}>{t("articles")}</span>
            <span role="button" tabIndex={0} onClick={() => onFilter("video")} onKeyDown={() => onFilter("video")} className={`filter-tag ${activeKey === 'video' ? "active-filter" : ""}`}>{t("videos")}</span>
            <span role="button" tabIndex={0} onClick={() => onFilter("other")} onKeyDown={() => onFilter("other")} className={`filter-tag ${activeKey === 'other' ? "active-filter" : ""}`}>{t("other")}</span>
        </div>
    )
}

export default FilterRessources;